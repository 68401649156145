<template>
  <div
    v-on-click-outside="handleBlur"
    class="rounded-md"
    :class="{ 'border border-gray-300': inputsVisible }"
    @keyup.tab="handleBlur"
  >
    <autoresizing-text-area
      id="note-input"
      v-model="inputText"
      class="w-full rounded-md overflow-y-hidden -mb-3 text-gray-700 text-sm"
      :class="
        inputsVisible
          ? 'border-transparent border-0 focus:outline-none -mb-7 focus:ring-0 focus:border-transparent focus-within:outline-none'
          : 'border-gray-300 border'
      "
      placeholder="Add note"
      :focused="inputsVisible"
      :rows="3"
      @focus="handleFocus"
      @change="syncSessionStorage"
    />
    <div
      class="border-t border-gray-300 mt-1 p-1 bg-gray-100 relative"
      :class="inputsVisible ? 'flex justify-between' : 'hidden'"
    >
      <!-- absolute -->
      <div class="w-36">
        <notes-dropdown
          v-if="noteType === NOTES_TYPES.APPLICATION_NOTE"
          v-model="selectedStatus"
          :options="dropdownOptions.options"
          :colors-for-options="dropdownOptions.colors"
          :active-flow-index="activeFlowIndex"
        />
      </div>
      <!-- end absolute -->

      <div
        class="flex items-center h-max"
        :class="{
          'w-full justify-end p-1': noteType === NOTES_TYPES.BUSINESS_NOTE
        }"
      >
        <note-visibility-button
          v-if="noteType === NOTES_TYPES.APPLICATION_NOTE"
          :value="visibilityScope"
          @change-scope="setVisibilityScope"
        />
        <business-notes-visibility-button
          v-if="noteType !== NOTES_TYPES.APPLICATION_NOTE"
          :visibility-scope="visibilityScope"
          :inputs-visible="inputsVisible"
          @change-scope="setVisibilityScope"
        />
        <button
          class="p-1 flex justify-center items-center w-10 rounded-md h-[36px]"
          :class="
            inputText
              ? 'bg-primary cursor-pointer'
              : ' bg-gray-300 cursor-not-allowed'
          "
          @click="addNote"
        >
          <icon-base
            height="20"
            width="20"
            :icon="IconSubmit"
            icon-color="white"
            class="ml-1"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { NOTES_TYPES, STATUS_COLOR_CODES } from "@/helpers/constants";
import { useAuth } from "@/hooks/auth";
import { useDealsBase } from "@/hooks/deals";
import useApplicationsStore from "@/stores/applications";
import { WORKFLOW_TO_APP_STATUS } from "@/helpers/constants/workflow";
import type {
  ApplicationStatus,
  IApplicationNote,
  AddApplicationNotePayload,
  EditApplicationNotePayload
} from "@/models/applications";
import type { PropType } from "vue";
import type { Note, noteVisibilityScope } from "@/models/common";
import type { IFlowRecord, IWorkflowStage } from "@/models/workflows";

import AutoresizingTextArea from "@/views/deals/components/dealNotes/AutoresizingTextArea.vue";
import NotesDropdown, { type Option } from "@/components/ui/NotesDropdown.vue";
import NoteVisibilityButton from "@/views/deals/components/dealNotes/NoteVisibilityButton.vue";
import BusinessNotesVisibilityButton from "@/views/deals/components/dealNotes/BusinessNotesVisibilityButton.vue";
import IconSubmit from "@/components/icons/IconSubmit.vue";

const props = defineProps({
  noteToEdit: Object as PropType<IApplicationNote | null>,
  noteType: {
    type: String as PropType<Note>,
    default: NOTES_TYPES.APPLICATION_NOTE
  }
});

const emit = defineEmits<{
  (eventName: "unsetEditNote"): void;
}>();

interface Payload {
  applicationId: string;
  noteId?: number;
  note: string;
  stage?: string;
  visibility_scope: number;
}

const ADD_NOTE_ACTIONS = {
  [NOTES_TYPES.APPLICATION_NOTE]: "addApplicationNote",
  [NOTES_TYPES.BUSINESS_NOTE]: "applications/addBusinessNote"
} as const;
const EDIT_NOTE_ACTIONS = {
  [NOTES_TYPES.APPLICATION_NOTE]: "editApplicationNote",
  [NOTES_TYPES.BUSINESS_NOTE]: "applications/updateBusinessNote"
} as const;

const { dispatch, getters } = useStore();
const { isLendflowUser } = useAuth();
const { activeDeal } = useDealsBase();
const applicationsStore = useApplicationsStore();

const flows = computed<IFlowRecord[]>(
  () => getters["workflows/activeTemplateFlow"]
);

const stages = computed<IWorkflowStage[]>(
  () => getters["workflows/activeTemplateStages"]
);

const activeFlowIndex = computed<number>(() => {
  return flows.value.findIndex(
    (flow) => flow.stage_id === activeStage.value.id
  );
});

const activeStage = computed<IWorkflowStage>(
  () => getters["workflows/activeStage"]
);

interface DropdownOptions {
  options: Option[];
  colors: Record<string, number>;
}

const dropdownOptions = computed(() =>
  flows.value.reduce<DropdownOptions>(
    (final, { id, name, stage_id }) => {
      const stageType = stages.value.find((stage) => stage.id === stage_id)
        ?.type as keyof typeof WORKFLOW_TO_APP_STATUS;
      const statusCode = WORKFLOW_TO_APP_STATUS[stageType] || 0;
      const color = STATUS_COLOR_CODES[statusCode as ApplicationStatus];

      final.options.push({ id, name });
      final.colors[id] = color;
      return final;
    },
    { options: [], colors: {} }
  )
);

const defaultVisibility = computed(() =>
  isLendflowUser && props.noteType !== NOTES_TYPES.BUSINESS_NOTE ? 2 : 1
);

const noteTypeAndDealId = computed(() =>
  props.noteType && activeDeal.value.id
    ? `${props.noteType}-${activeDeal.value.id}`
    : ""
);

const inputText = ref(
  window.sessionStorage.getItem(noteTypeAndDealId.value) || ""
);
const inputsVisible = ref(false);
const visibilityScope = ref<noteVisibilityScope>(defaultVisibility.value);
const selectedStatus = ref(props.noteToEdit?.stage);

const inputId = computed(() => props.noteToEdit?.id);

const handleFocus = () => {
  inputsVisible.value = true;
};

const setVisibilityScope = (visibility: noteVisibilityScope) => {
  visibilityScope.value = visibility;
};

const handleBlur = (evt: KeyboardEvent | MouseEvent) => {
  const target = evt.target as HTMLElement;
  const targetClass = target ? target.getAttribute("class") || "" : "";
  if (
    inputText.value.length > 0 ||
    props.noteToEdit ||
    targetClass.startsWith("resizable") ||
    targetClass.indexOf("drag-el") > -1
  ) {
    return;
  }
  inputsVisible.value = false;
};

const syncSessionStorage = (event?: Event) => {
  const value = (event?.target as HTMLTextAreaElement)?.value;
  if (value) {
    window.sessionStorage.setItem(noteTypeAndDealId.value, value);
    return;
  }
  window.sessionStorage.removeItem(noteTypeAndDealId.value);
};

const addNote = async () => {
  if (!inputText.value.length) {
    return;
  }

  const payload: Payload = {
    applicationId: activeDeal.value.id,
    note: inputText.value,
    visibility_scope: visibilityScope.value
  };
  if (props.noteType === NOTES_TYPES.APPLICATION_NOTE) {
    payload.stage = selectedStatus.value;
  }

  const action = inputId.value
    ? EDIT_NOTE_ACTIONS[props.noteType]
    : ADD_NOTE_ACTIONS[props.noteType];

  if (inputId.value) {
    payload.noteId = Number(inputId.value);
    emit("unsetEditNote");
  }
  if (props.noteType === NOTES_TYPES.BUSINESS_NOTE) {
    await dispatch(action, payload);
  } else {
    inputId.value
      ? await applicationsStore.editApplicationNote(
          payload as EditApplicationNotePayload
        )
      : await applicationsStore.addApplicationNote(
          payload as AddApplicationNotePayload
        );
  }
  inputText.value = "";
  visibilityScope.value = defaultVisibility.value;
  inputsVisible.value = false;
  window.sessionStorage.removeItem(noteTypeAndDealId.value);
};

watch(
  () => props.noteToEdit,
  () => {
    if (props.noteToEdit) {
      inputText.value = props.noteToEdit?.comment;
      visibilityScope.value = props.noteToEdit?.visibility_scope;
      inputsVisible.value = true;
      selectedStatus.value = props.noteToEdit.stage;
      return;
    }
  }
);

watch([flows, activeFlowIndex], ([newFlows, newFlowIndex]) => {
  if (newFlows && dropdownOptions.value?.options.length) {
    selectedStatus.value = dropdownOptions.value.options[newFlowIndex].id;
  }
});
</script>
